import React from 'react'
import styled from 'styled-components'
import Header from '../Header/Header'

const Journal = () => {
  return (
    <div>

    </div>
  )
}

export default Journal

