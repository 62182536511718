import React from 'react'
import Cont from '../Cont/Cont'
import Header from '../Header/Header'

const Contact = () => {
  return (
    <div>
      <Cont />
    </div>
  )
}

export default Contact