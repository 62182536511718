import React from 'react'
import Adminhead from '../Adminhead/Adminhead'
import Homescreen from '../Homescreen/Homescreen'

const Admin = () => {
  return (
    <div>
        <Adminhead />
        <Homescreen />
    </div>
  )
}

export default Admin