import React from 'react'
import styled from 'styled-components'

const Visionhold = () => {
  return (
    <Container>
        <Wrapper>
            <Vision>
                <Top><h1>Vision</h1></Top>
                <P><p>The vision of the Center for Undergraduate Research is to create and promote a research culture that seeks to find solutions to challenges that lead to National Development through undergraduate research. .</p></P>
            </Vision>
            <Mission>
                <Top2><h1>Mission</h1></Top2>
                <P><p>Our mission is to support and promote critical thinking that will enhance originality of undergraduate students’ research through mentorship.  </p></P>
            </Mission>
        </Wrapper>
    </Container>
  )
}

export default Visionhold
const P = styled.div`
    p{
        margin: 0;
        color: #1d1d1d;
        line-height: 1.9rem;
    }
`
const Top2 = styled.div`
    h1{
        color: #219653;
        font-size: 35px;
        margin-top: 0px;
         @media screen and (max-width: 425px) {
            font-size: 30px;
        }
        @media screen and (max-width: 425px) {
            font-size: 25px;
        }
    }
`
const Top = styled.div`
    h1{
        color: #219653;
        font-size: 35px;
         @media screen and (max-width: 425px) {
            font-size: 30px;
        }
        @media screen and (max-width: 425px) {
            font-size: 25px;
        }
    }
`
const Mission = styled.div`
    width: 45%;
    display: flex;
    padding-left: 25px;
    padding-right: 10px;
    padding-top: 15px;
    flex-direction: column;
    /* background-color: green; */
    border-radius: 15px;
    padding-bottom: 45px;
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
    :hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        background-color: white;
    }
`
const Vision = styled.div`
    width: 45%;
    display: flex;
    padding-left: 25px;
    flex-direction: column;
    padding-right: 10px;
    /* background-color: green; */
    border-radius: 15px;
    padding-bottom: 45px;
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
    :hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        background-color: white;
    }
`
const Wrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    flex-wrap: wrap;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`