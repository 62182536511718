import React from "react";
import styled from "styled-components";
import Pitch from "../Pitch/Pitch";
import Solution from "../Solutions/Solutions";
import Team from "../Team/Team";
import TopNotch from "../TopNotch/TopNotch";
import Mission from "../Mission/Mission"
import Build from "../Builds/Build"
import Us from "./Us";



const ABout = () => {
  return (
    <Container>
      <Mission />
      <Solution />
      {/* <Build /> */}
      {/* <Pitch /> */}
      {/* <Pitch /> */}
      {/* <TopNotch />
      <Team /> */}
    </Container>
  );
};

export default ABout;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
