import React from 'react'
import styled from 'styled-components'

const Cont = () => {
  return (
    <Container>
        <Wrapper>
            <Top>
                <h1>Get in Touch</h1>
            </Top>
            <Hold>
                <Left>
                    <Up><h3>Contact us</h3></Up>
                    <p>Don't hesitate to reach out to us. We'll respond as soon as possible</p>
                    <p>No. 6 Otunba Ogungbe Crescent, Lekki Phase 1, Lagos, Nigeria.</p>
                    <p>hello@betalifehealth.com</p>
                </Left>
                <Right>
                    <Input placeholder='Name'/>
                    <Input placeholder='Email'/>
                    <Input placeholder='Subject'/>
                    <Box placeholder='Message'/>
                    <Button>Submit</Button>
                </Right>
            </Hold>
        </Wrapper>
    </Container>
  )
}

export default Cont
const Button = styled.button`
    width: 120px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #219653;
    margin-top: 13px;
    border: none;
    outline: none;
    color: white;
    border-radius: 50px;
    font-size: 17px;
    cursor: pointer;
`
const Box = styled.textarea`
    /* width: 200px; */
    display: flex;
    padding-left: 15px;
    padding-top: 20px;
    height: 90px;
    border: 1px solid #CED4DA;
    outline: none;
    resize: none;
    ::placeholder{
        font-size: 17px;
    }
`
const Input = styled.input`
    /* width: 100%; */
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border: 1px solid #CED4DA;
    margin-bottom: 10px;
    ::placeholder{
        font-size: 17px;
    }
`
const Right = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    /* background-color: red; */
    @media screen and (max-width: 768px){
        width: 100%;
        margin-top: 15px;
    }
`
const Up = styled.div`
    h3{
        font-size: 23px;
        margin: 0;
    }
`
const Left = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 40px;
    p{

    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`
const Hold = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: space-between;
    flex-wrap: wrap;
`
const Top = styled.div`
    h1{

    }
`
const Wrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    /* background-color: red; */
    margin-top: 80px;
    flex-direction: column;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 70px;
`