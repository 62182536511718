import React from 'react'
import styled from 'styled-components'
import {AiFillHome, AiFillCar} from "react-icons/ai"
import {FaHeartbeat} from "react-icons/fa"
import {BsFillDisplayFill} from "react-icons/bs"
import {MdOutlineAirplanemodeActive} from "react-icons/md"
import handphone from "../Assets/handphone.png"
import hands from "../Assets/woman.jpeg"
import ShowMoreText from "react-show-more-text"

const Explore = () => {
    const executeOnclick = () => {

    }
  return (
    <Container>
        <Wrapper>
            <Top><h1>Our Goals</h1></Top>
            <Cardshold>
                <Card>
                    <Down>
                        <Circle>
                            <AiFillHome />
                        </Circle>
                    </Down>
                    <Up>Entrepreneurial ventures</Up>
                    <p>To turn research findings into entrepreneurial ventures and or enhance entrepreneurial ventures in the society.</p>
                </Card>
                <Card>
                    <Down>
                        <Circle2>
                            <FaHeartbeat />
                        </Circle2>
                    </Down>
                    <Up>promote National Development</Up>
                    <p>Use research findings to promote National Development through the relevant Government parastatals or agencies</p>
                </Card>
                <Card>
                    <Down>
                        <Circle>
                            <AiFillHome />
                        </Circle>
                    </Down>
                    <Up>Entrepreneurial ventures</Up>
                    <p>To turn research findings into entrepreneurial ventures and or enhance entrepreneurial ventures in the society.</p>
                </Card>
            </Cardshold>
            <Phonehold>
                <Right>
                    <Box><Img src={hands} /></Box>
                </Right>
                <Left>
                    <Pay>
                        <h2>Director Welcome Address</h2>
                    </Pay>
                    <ShowMoreText
                        /* Default options */
                         lines={5}
                         more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="show-more-less-clickable"
                        onClick={executeOnclick}
                        expanded={false}
                        width={500}
                        truncatedEndingComponent={"... "}
                        
                    >
                        <P><p>It is my pleasure to welcome you to the Centre 
                        for Undergraduate Research where we create a research 
                        culture among our undergraduate students. The Centre for 
                        Undergraduate Research is established to promote research 
                        and create opportunities for undergraduate students across 
                        all Departments in the University. The Center helps students 
                        attain their full potentials through research thereby building 
                        and developing their research skills, and inculcating the spirit 
                        of focusing on finding solutions to the challenges plaguing our 
                        society. Undergraduate research is a research in which an 
                        undergraduate links with a mentor to provide solutions to the 
                        problems and challenges in our society. These challenges could be 
                        university based, Industrial, community or national issues and policies. 
                        The whole concept is to create innovative ideas that will enhance 
                        productivity and profitability in the industry and help shape our 
                        society. The researches can be carried out in the laboratory, studio, 
                        on the field or library based across disciplines.</p>
                    </P>
                    </ShowMoreText>
                </Left>
            </Phonehold>
        </Wrapper>
    </Container>
  )
}

export default Explore
const Box = styled.div`
    width: 250px;
    height: 250px;
    border-radius: 150px;
    overflow: hidden;
`
const Img = styled.img`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 320px) {
        height: 250px;
    }
    @media screen and (max-width: 375px) {
        height: 270px;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        height: 350px;
        margin-top: 10px;
    }
`
const Right = styled.div`
    width: 35%;
    display: flex;
    /* border-radius: 50%; */
    justify-content: center;
    padding-right: 25px;
    padding-top: 10px;
    overflow: hidden;
    @media screen and (max-width: 320px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        /* justify-content: flex-end; */
    }
`
const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 43px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #219653;
    color: white;
    margin-top: 70px;
    /* margin-left: 80px; */
    @media screen and (max-width: 904px) {
        margin-top: 30px;
        margin-left: 5px;
    }
`
const P = styled.div`
    color: #1d1d1d;
    
    p{
        font-weight: 500;
        text-align: justify;
    /* font-size: 18px; */
    line-height: 1.9rem;
    color: #1d1d1d;
    /* margin-left: 80px; */
    width: 80%;
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        margin-left: 5px;
    }
    @media screen and (max-width: 375px) {
        /* text-align: center; */
    }
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`
const Pay = styled.div`
    width: 100%;
    /* margin-left: 80px; */
    flex-wrap: wrap;
    margin: 0;
    h2{
        font-weight: 700;
        font-size: 33px;
        line-height: 2.5rem;
        color: #219653;
        @media screen and (max-width: 904px) {
        font-size: 30px;
        color: #219653;
        @media screen and (max-width: 320px) {
        font-size: 27px;
    }
    @media screen and (max-width: 375px) {
        font-size: 25px;
    }
    }
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        margin-left: 5px;
    }
    @media screen and (max-width: 320px) {
        text-align: center;
    }
    @media screen and (max-width: 375px) {
        text-align: center;
    }
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`
const Left = styled.div`
    width: 55%;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    /* background-color: red; */
    @media screen and (max-width: 904px) {
        width: 100%;
    }
`
const Phonehold = styled.div`
    width: 85%;
    display: flex;
    align-items: center;
    background-color: white;
    overflow: hidden;
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    justify-content: space-between;
    /* padding-top: 60px; */
    flex-wrap: wrap;
    border-radius: 20px;
    @media screen and (max-width: 904px) {
        width: 100%;
        padding: 10px;
    }
`
const Circle5 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #FBDB5A;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card6 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #FACC15;
    border-radius: 10px;
`
const Card5 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #FACC15;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle4 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #51539F;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card4 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #3E4095;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle3 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #8352AB;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card3 = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    padding-bottom: 15px;
    background-color: #763FA2;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle2 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #E02D98;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #DD168D;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #37A064;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
    /* @media screen and (max-width: 320px) {
        width: 50px;
        height: 50px;
        font-size: 25px;
        margin-right: 0px;
        margin-top: -10px;
    } */
`
const Down = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    /* @media screen and (max-width: 320px) {
        justify-content: center;
        display: flex;
    } */
`
const Up = styled.div`
    font-size: 16px;
    line-height: 1.25rem;
    color: #fff;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    text-align: center;
    
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 260px;
    padding-bottom: 15px;
    background-color: #219653;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
    p{
        text-align: center;
        color: white;
        font-size: 14px;
    }
    @media screen and (max-width: 320px) {
        width: 100%;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
    }
    
    @media screen and (max-width: 425px) {
        width: 100%;
    }
`
const Cardshold = styled.div`
    width: 86%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
`
const Top = styled.div`
    h1{
        font-weight: 700;
        font-size: 33px;
        line-height: 2rem;
        color: #219653;
        @media screen and (max-width: 320px) {
        line-height: 2.5rem;
        font-size: 27px;
    }
    @media screen and (max-width: 395px) {
        font-size: 25px;
    }
    }
    @media screen and (max-width: 320px) {
        text-align: center;
    }
`
const Wrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
`