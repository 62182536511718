import React from 'react'
import styled from 'styled-components'
import mapsnip from "../Assets/focus.jpg"

const Hero = () => {
  return (
    <Container>
        <Wrapper>
            <Left>
                <Top><h1>Center for Undergraduate. Research University of Abuja</h1></Top>
                <P>Promoting National Development through Research Tapping Talents, Fostering Research Culture, Creating Opportunities, Promoting National Development
                </P>
                <Buttonhold>
                    <Button>Contact us</Button>
                </Buttonhold>
                <Bottomhold>
                    <Card></Card>
                    <Last>
                        <Padhold>
                            CUR Key Figure
                        </Padhold>
                        <Numberhold>
                            <Hold>
                               <Up>5,000+</Up> 
                               <Down>Researchers</Down>
                            </Hold>
                            <Hold>
                               <Up>11,191+</Up> 
                               <Down>Grants Awarded</Down>
                            </Hold>
                            <Hold>
                               <Up>99%</Up> 
                               <Down>Claims fulfilment</Down>
                            </Hold>
                        </Numberhold>
                    </Last>
                </Bottomhold>
            </Left>
            <Right>
                <Map src={mapsnip}/>
            </Right>
        </Wrapper>
    </Container>
  )
}

export default Hero
const Map = styled.img`
    height: 400px;
    @media screen and (max-width: 768px) {
        height: 300px;
    }
    @media screen and (max-width: 425px) {
        height: 250px;
    }
    @media screen and (max-width: 375px) {
        height: 200px;
    }
`
const Right = styled.div`
    width: 50%;
    display: flex;
    margin-top: 15px;
    /* background-color: red; */
    @media screen and (max-width: 874px) {
        margin-top: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const Down = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 10px;

`
const Up = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #219653;
`
const Hold = styled.div`
    width: 130px;
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
`
const Numberhold = styled.div`
    display: flex;
    align-items: center;
`
const Padhold = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #219653;
    width: 180px;
`
const Last = styled.div`
    width: 100%;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
`
const Card = styled.div`
    width: 80px;
    height: 7px;
    background-color: #219653;
    border-radius: 2px;
`
const Bottomhold = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    @media screen and (max-width: 874px) {
        display: none;
    }
`
const Button2 = styled.button`
    color: #587ECB;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 43px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-left: 20px;
    background-color: #E7E7F0;
`
const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 43px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #219653;
    color: white;
`
const Buttonhold = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
`
const P = styled.div`
    font-size: 1.125rem;
    line-height: 1.9rem;
    font-weight: 500;
    margin-top: 1rem;
    width: 400px;
    color: #1d1d1d;
    @media screen and (max-width: 874px) {
        width: 100%;
    }
     @media screen and (max-width: 320px) {
        font-size: 15px;
    }
`
const Top = styled.div`
    width: 500px;
    h1{
        font-size: 40px;
        font-weight: 700;
        line-height: 2.5rem;
        color: #219653;
        margin: 0;
        font-family: "Avenir", sans-serif;
        @media screen and (max-width: 425px) {
        font-size: 30px;
    }
    @media screen and (max-width: 375px) {
        font-size: 27px;
    }
        @media screen and (max-width: 320px) {
        font-size: 25px;
    }
    }
    @media screen and (max-width: 874px) {
        width: 100%;
    }
`
const Left = styled.div`
    width: 50%;
    /* height: 130px; */
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    /* background-color: green; */
    @media screen and (max-width: 874px) {
        width: 100%;
    }
    @media screen and (max-width: 874px) {
        padding-top: 10px;
    }
`

const Wrapper = styled.div`
    width: 80%;
    display: flex;
    height: 100%;
    margin-top: 100px;
    /* background-color: green; */
    flex-wrap: wrap;
    /* @media screen and (max-width: 768px) {
        margin-top: 10px;
    } */
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FAFAFA;
    justify-content: center;
`